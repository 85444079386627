import { sendMultipleCipher } from '@/api/common.js';

export function register(data) {
  return sendMultipleCipher(
    '/api/register/auth/decryptRegister',
    data,
  )
}

export function confirmRegister(data) {
  return sendMultipleCipher(
    '/api/register/auth/decryptConfirmRegister',
    data,
  )
}
