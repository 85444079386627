<template>
  <div v-if="formData" class="container p-4">
    <div class="mb-5 col-12 px-0 px-sm-3 mx-auto text-left">以下の内容で応募を行います。入力情報を確認してください。</div>
    <form ref="form1" @submit.prevent>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">同意日</label>
        <div class="col-sm-6">
          {{ `${now.year}年${now.month}月${now.day}日` }}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">氏名</label>
        <div class="col-sm-6">{{ formData.familyName }} {{ formData.firstName }}</div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">年齢</label>
        <div class="col-sm-6">
          <p>{{ formData.age }}</p>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">性別</label>
        <div class="col-sm-6">
          <template v-if="formData.sex == CONST.MEMBER.SEX.MALE"> 男性 </template>
          <template v-if="formData.sex == CONST.MEMBER.SEX.FEMALE"> 女性 </template>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">郵便番号</label>
        <div class="col-sm-6">
          {{ formData.zipCode }}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">住所</label>
        <div class="col-sm-6 text-left">
          {{ formData.address }}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label p-sm-1">メールアドレス</label>
        <div class="col-sm-6">
          {{ formData.email }}
        </div>
      </div>
      <div class="form-group row justify-content-center mt-5">
        <div class="col-sm-6">
          <router-link :to="{name: 'Register'}" class="btn_next btn btn-secondary" role="button">修正する</router-link>
        </div>
      </div>
      <div class="mt-5 mb-3 col-12 px-0 px-sm-3 mx-auto text-left">メールアドレスの存在確認を行うため、以下ボタンをクリックして認証URLをメール送信してください。</div>
      <div class="form-group row justify-content-center mb-4">
        <button class="btn_next btn btn-info" type="button" @click="tempRegister">認証メール送信</button>
      </div>
      <div class="mt-5 mb-3 col-12 px-0 px-sm-3 mx-auto text-left">認証メールの送信後、メールを確認し、記載している認証URLにアクセスすると応募が完了されます。<br/><br/>※携帯キャリアのメール、icloud.comのメールなど特に自動送信メールが迷惑メールに振り分けられる、またはブロックされる場合があります。認証メールがすぐに届かない場合は迷惑メールBoxを確認して頂き、それでも見つからない場合はお手数ですが、gmail, yahoo, hotmailなどのアドレスから送信を行ってください。</div>
    </form>
  </div>
</template>
<script>
import { Mixin } from '@/mixin.js'
import { apiError } from '@/api/common.js'
import { checkStatus } from '@/utils/common.js'
import { register } from '@/api/register.js'
import { CONST } from '@/constants.js'
import { aesEncryption } from '@/utils/cipher'

export default {
  mixins: [Mixin],
  data() {
    const now = new Date()
    return {
      CONST: CONST,
      now: {
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate(),
      },
      formData: null,
      answerInput: null,
      pin: null,
      memberCode: null,
    }
  },
  created() {
    // ステータスチェック
    checkStatus(this, 6)

    const answerInputJson = localStorage.getItem('answerInputJson')
    this.answerInput = JSON.parse(answerInputJson)

    const formDataJson = localStorage.getItem('registerFormDataJson')
    // フォーム入力データがない場合、登録画面へ遷移
    if (formDataJson == null) {
      this.$router.replace({name: 'Register'})
      return
    }
    this.formData = JSON.parse(formDataJson)
  },
  methods: {
    // 会員仮登録
    async tempRegister(event) {
      // 連打対策
      event.target.disabled = true

      const tempId = localStorage.getItem('tempId')

      if (!tempId) {
        alert('一時IDが見つかりません。画面をリロードしてもう一度送信してください。')
      } else {
        const res = await register({
          data: aesEncryption(JSON.stringify({
            family_name: this.formData.familyName,
            first_name: this.formData.firstName,
            age: this.formData.age,
            sex: this.formData.sex,
            zip_code: this.formData.zipCode,
            address: this.formData.address,
            email: this.formData.email,
            answers: this.answerInput,
            tempId: tempId,
            ua: ((navigator && navigator.userAgent) ? navigator.userAgent : ''),
          }), '')
        }).catch(apiError)

        if (res && res.value && res.value.member_code) {
          this.memberCode = res.value.member_code
          // alert('The pin code has been sent to your email address, please enter the pin code.')
          // alert('PINコードを入力されたメールアドレスに送信しました。PINコードを入力して「送信」ボタンを押してください。')
          alert('認証メールを入力されたメールアドレスに送信しました。メールに記載のURLにアクセスして、応募を完了してください。')
        }
      }
      event.target.disabled = false
    },
    // // 会員本登録
    // async register() {
    //   if (!this.$refs.form1.reportValidity()) {
    //     return
    //   }
    //   if (this.memberCode === null) {
    //     // alert('You are not registered.')
    //     alert('まだメール認証がされておりません。もう一度PINコードを送信してください。')
    //     return
    //   } else if (!this.pin.match(/^[0-9]{6}$/)) {
    //     alert('PINコードの形式が不正です。6桁の半角数字で入力してください。')
    //     return
    //   }

    //   // 本登録API実行
    //   const res = await confirmRegister({
    //     member_code: this.memberCode,
    //     pin: this.pin,
    //   }).catch(apiError)

    //   if (res && res.value && res.value.member_code) {
    //     // localStorageから同意フラグ、事前アンケートの回答データ、フォーム入力データを削除
    //     localStorage.removeItem('applyFlag')
    //     localStorage.removeItem('movieFlag')
    //     localStorage.removeItem('consentFlag')
    //     localStorage.removeItem('answerInputJson')
    //     localStorage.removeItem('registerFormDataJson')
    //     localStorage.removeItem('tempId')
    //     localStorage.removeItem('currentStatus')
    //     localStorage.setItem('preAnswerComplete', '1')
    //     this.$router.push({name: 'PreAnswerComplete'})
    //   }
    // },
  },
}
</script>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
